import './App.css';
import { SoundSpace } from './SoundSpace.js';
import Draggable from 'react-draggable';
import useMeasure from 'react-use-measure';
import { useContext, createContext, useEffect, useState, useRef } from 'react';

const DraggableInRoom = ({ label, onDrag, startAt: [x, y], styleObj = {} }) => {
  const nodeRef = useRef(null);
  return (
    <Draggable
      bounds="parent"
      nodeRef={nodeRef}
      defaultPosition={{ x, y }}
      onDrag={(event) => onDrag(event)}
    >
      <div ref={nodeRef} className="item" id={label} style={styleObj}>
        {label}
      </div>
    </Draggable>
  );
};

const roomContext = createContext(
  new SoundSpace({
    room: [500, 500, 500],
    earLocation: [250, 250, 250],
  })
);

const initialSoundState = {
  earLocation: [250, 250, 250],
  sounds: {
    oldman: {
      location: [100, 250, 250],
      url: 'https://files.cargocollective.com/c1064184/OLD-MAN.mp3',
    },
    barwork: {
      location: [350, 350, 250],
      url: 'https://files.cargocollective.com/c1064184/Bar-Work.mp3',
    },
    rain: {
      location: [250, 100, 250],
      url: 'https://files.cargocollective.com/c1064184/Rain.mp3',
    },
    trad: {
      location: [50, 10, 250],
      url: 'https://files.cargocollective.com/c1064184/Trad-Music.mp3',
    },
    chat: {
      location: [350, 100, 250],
      url: 'https://files.cargocollective.com/c1064184/TheChat.m4a',
    },
    fire: {
      location: [50, 50, 250],
      url: 'https://files.cargocollective.com/c1064184/Fireplace.mp3',
    },
  },
};

function App() {
  const room = useContext(roomContext);

  // play button
  const [play, setPlay] = useState(false);
  useEffect(() => {
    if (play) {
      room.resume();
    } else {
      room.suspend();
    }
    return () => room.cleanup();
  }, [play]);

  // sound config, update what sounds exist, where the ear is
  const [soundState, setSoundState] = useState(initialSoundState);

  useEffect(() => {
    room.setSounds(soundState);
  }, [soundState]);

  // when a sound is moved
  const [ref, bounds] = useMeasure();
  const xyInBounds = (event) => {
    let x = event.clientX - bounds.left;
    let y = event.clientY - bounds.top;
    // normalize mouse overshoot
    if (x < 0) {
      x = 0;
    }
    if (x > bounds.width) {
      x = bounds.width;
    }
    if (y < 0) {
      y = 0;
    }
    if (y > bounds.height) {
      y = bounds.height;
    }
    return [x, y];
  };

  const onDragForSound = (soundName) => (event) => {
    const [x, y] = xyInBounds(event);
    soundState.sounds[soundName].location = room.findLocationFromPixels({
      width: bounds.width,
      height: bounds.height,
      x,
      y,
    });
    setSoundState({ ...soundState });
  };

  const onDragForEar = (event) => {
    const [x, y] = xyInBounds(event);
    soundState.earLocation = room.findLocationFromPixels({
      width: bounds.width,
      height: bounds.height,
      x,
      y,
    });
    setSoundState({ ...soundState });
  };

  return (
    <div className="App">
      <a href="#" onClick={() => setPlay(!play)}>
        MAKE A NOISE
      </a>
      <div
        style={{ position: 'relative', width: '100%', height: '100vh'}}
        ref={ref}
        key="room"
      >
        {bounds.width && (
          <DraggableInRoom
            key="ear"
            label=""
            startAt={room.findPixelsFromLocation({
              location: [250, 250, 250],
              width: bounds.width,
              height: bounds.height,
            })}
            onDrag={onDragForEar}
            styleObj={{
              backgroundImage: `url("head.png")`,
              backgroundPosition: 'center', 
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
            }}
          />
        )}

        {bounds.width &&
          Object.keys(soundState.sounds).map((soundName) => (
            <DraggableInRoom
              key={soundName}
              label={soundName}
              startAt={room.findPixelsFromLocation({
                location: initialSoundState.sounds[soundName].location,
                width: bounds.width,
                height: bounds.height,
              })}
              onDrag={onDragForSound(soundName)}
            />
          ))}
      </div>
    </div>
  );
}

export default App;
