const fetchAsAudioBuffer = async (url, audioContext) => {
  const response = await fetch(url);
  const arrayBuffer = await response.arrayBuffer();
  return audioContext.decodeAudioData(arrayBuffer);
};

// its a class, but the only accessor is to update the whole state at once...
// and that thing has to figure out how to move from a -> b, an async operation
class SoundSpace {
  constructor({ room, earLocation }) {
    this.room = room;
    console.log('-setup audio context');
    this.audioContext = new AudioContext();
    this.listener = this.audioContext.listener;
    this.setListenerPosition(earLocation);
    this.currentSounds = {};
  }

  findLocationFromPixels({ width, height, x, y }) {
    // return the location in the room using some arbitrary pixel rectangle and point
    // within the rectangle as an analgous map
    return [this.room[0] * (x / width), this.room[1] * (y / height), 250];
  }

  findPixelsFromLocation({ width, height, location: [x, y] }) {
    // return the analogous location in the pixel area for the room location
    return [width * (x / this.room[0]), height * (y / this.room[1])];
  }

  setListenerPosition(location, listenerForward = [0, 1, 0], listenerUp = [0, 0, 1]) {
    if (this.listener.positionX) {
      // update listener position
      this.listener.positionX.value = location[0];
      this.listener.positionY.value = location[1];
      this.listener.positionZ.value = location[2];

      this.listener.forwardX.value = listenerForward[0];
      this.listener.forwardY.value = listenerForward[1];
      this.listener.forwardZ.value = listenerForward[2];

      this.listener.upX.value = listenerUp[0];
      this.listener.upY.value = listenerUp[1];
      this.listener.upZ.value = listenerUp[2];

    } else {
      this.listener.setPosition(...location);
    }
  }

  setSounds(soundState) {
    this.setListenerPosition(soundState.earLocation);
    //fetch missing sounds
    Object.keys(soundState.sounds)
      .filter((soundName) => !this.currentSounds[soundName])
      .forEach(async (soundName) => {
        console.log('-new sound');
        this.currentSounds[soundName] = {
          thenQueue: [],
          sourceSetupPromise: fetchAsAudioBuffer(
            soundState.sounds[soundName].url,
            this.audioContext
          ).then((buffer) =>
            this._initSound(soundName, soundState.sounds[soundName], buffer)
          ),
        };
      });

    // remove omitted sounds
    Object.keys(this.currentSounds)
      .filter((soundName) => !soundState.sounds[soundName])
      .forEach((soundName) => this._removeSound(soundName));

    // update all
    Object.entries(this.currentSounds).forEach(([soundName, sound]) => {
      sound.thenQueue.unshift(() => {
        // change loop freq? change location?
        const pos = soundState.sounds[soundName].location;
        this.currentSounds[soundName].location = pos;
        const panner = sound.panner;
        if (panner.positionX) {
          panner.positionX.value = pos[0];
          panner.positionY.value = pos[1];
          panner.positionZ.value = pos[2];
        } else {
          panner.setPosition(...pos);
        }
      });

      // only do the newest thing in the thenQueue, get rid of the rest
      // so that once the sound loads we will start making noise
      // in the correct current state
      sound.sourceSetupPromise.then(() => {
        if (sound.thenQueue.length > 0) {
          sound.thenQueue[0]();
        }
        sound.thenQueue = [];
      });
    });
  }

  _initSound(soundName, soundConfig, buffer) {
    const source = this.audioContext.createBufferSource(1);
    const panner = this.audioContext.createPanner();
    this.currentSounds[soundName].source = source;
    this.currentSounds[soundName].panner = panner;
    source.buffer = buffer;
    source.playbackRate.value = 1.0;
    source.loop = true;
    // whoa: https://developer.mozilla.org/en-US/docs/Web/API/PannerNode/panningModel
    panner.panningModel = 'HRTF';
    // https://developer.mozilla.org/en-US/docs/Web/API/PannerNode/distanceModel
    panner.distanceModel = 'inverse';

    // these settings are probably all wrong, more research?
    panner.refDistance = 1;
    panner.maxDistance = 10000;
    panner.rolloffFactor = 1;
    panner.coneInnerAngle = 360;
    panner.coneOuterAngle = 90;
    panner.coneOuterGain = 1;
    panner.setOrientation(1, 0, 0);
    source.connect(panner);
    panner.connect(this.audioContext.destination);
    source.start(0);
    panner.setPosition(...soundConfig.location);
  }

  _removeSound(soundName) {
    console.log('-sound remove', soundName);
    if (this.currentSounds[soundName]) {
      const sound = this.currentSounds[soundName];
      delete this.currentSounds[sound];
      sound.source?.stop();
    }
  }

  suspend() {
    this.audioContext.suspend();
  }

  resume() {
    this.audioContext.resume();
  }

  cleanup() {
    // fixme I dunno, stop the sounds?
    this.audioContext.suspend();
  }
}

export { SoundSpace };
